<template>

    <page-placeholder v-if="project.isLoaded">
        <v-row>
            <v-col :cols="12">
                <progression-axis-top-action-priorities/>
            </v-col>

            <v-col :cols="12">
                <progression-axis-improvement-way/>
            </v-col>


            <v-col :cols="12">
                <market-segment-best-progression-potential/>
            </v-col>
        </v-row>
    </page-placeholder>

</template>


<script>

import PagePlaceholder from "@/components/yooda-components/PagePlaceholder.vue";
import ProgressionAxisTopActionPriorities from "@/components/yooda-components/app/progression-axis/ProgressionAxisTopActionPriorities.vue";
import ProgressionAxisImprovementWay from "@/components/yooda-components/app/progression-axis/ProgressionAxisImprovementWay.vue";
import MarketSegmentBestProgressionPotential from "@/components/yooda-components/app/market-segment/MarketSegmentBestProgressionPotential.vue";


export default {
    components: {
        PagePlaceholder,
        ProgressionAxisTopActionPriorities,
        ProgressionAxisImprovementWay,
        MarketSegmentBestProgressionPotential,
    },
}

</script>


